var LocalStorageCache = /** @class */ (function () {
    function LocalStorageCache() {
    }
    LocalStorageCache.prototype.set = function (key, value) {
        try {
            localStorage.setItem(key, btoa(value));
        }
        catch (ex) {
            // local storage is unavailable
        }
    };
    LocalStorageCache.prototype.get = function (key) {
        try {
            var configString = localStorage.getItem(key);
            if (configString) {
                return atob(configString);
            }
        }
        catch (ex) {
            // local storage is unavailable or invalid cache value in localstorage
        }
        return void 0;
    };
    return LocalStorageCache;
}());
export { LocalStorageCache };
