import { ConfigCatClient } from "./ConfigCatClient";
import { PollingMode } from "./ConfigCatClientOptions";
import { ConfigCatConsoleLogger, LogLevel } from "./ConfigCatLogger";
import { setupPolyfills } from "./Polyfills";
setupPolyfills();
/**
 * Returns an instance of `ConfigCatClient` for the specified SDK Key.
 * @remarks This method returns a single, shared instance per each distinct SDK Key.
 * That is, a new client object is created only when there is none available for the specified SDK Key.
 * Otherwise, the already created instance is returned (in which case the `pollingMode`, `options` and `configCatKernel` arguments are ignored).
 * So, please keep in mind that when you make multiple calls to this method using the same SDK Key, you may end up with multiple references to the same client object.
 * @param sdkKey SDK Key to access the ConfigCat config.
 * @param pollingMode The polling mode to use.
 * @param options Options for the specified polling mode.
 */
export function getClient(sdkKey, pollingMode, options, configCatKernel) {
    return ConfigCatClient.get(sdkKey, pollingMode, options, configCatKernel);
}
/**
 * Disposes all existing `ConfigCatClient` instances.
 */
export function disposeAllClients() {
    ConfigCatClient.disposeAll();
}
/**
 * Creates an instance of `ConfigCatConsoleLogger`.
 * @param logLevel Log level (the minimum level to use for filtering log events).
 */
export function createConsoleLogger(logLevel) {
    return new ConfigCatConsoleLogger(logLevel);
}
export { FetchStatus, FetchResult, FetchError } from "./ConfigFetcher";
export { ExternalConfigCache } from "./ConfigCatCache";
export { FlagOverrides, MapOverrideDataSource } from "./FlagOverrides";
/* Public types for end users */
// List types here which are part of the public API of platform-specific SDKs, thus, should be exposed to end users.
// These exports should be re-exported in the entry module of each platform-specific SDK!
export { PollingMode };
export { DataGovernance } from "./ConfigCatClientOptions";
export { LogLevel };
export { FormattableLogMessage } from "./ConfigCatLogger";
export { SettingType, Comparator } from "./ProjectConfig";
export { SettingKeyValue } from "./ConfigCatClient";
export { User } from "./RolloutEvaluator";
export { OverrideBehaviour } from "./FlagOverrides";
export { RefreshResult } from "./ConfigServiceBase";
export { ClientReadyState } from "./Hooks";
