import { __awaiter, __generator } from "tslib";
import { ProjectConfig } from "./ProjectConfig";
var InMemoryConfigCache = /** @class */ (function () {
    function InMemoryConfigCache() {
        this.cachedConfig = ProjectConfig.empty;
    }
    InMemoryConfigCache.prototype.set = function (_key, config) {
        this.cachedConfig = config;
    };
    InMemoryConfigCache.prototype.get = function (_key) {
        return this.cachedConfig;
    };
    InMemoryConfigCache.prototype.getInMemory = function () {
        return this.cachedConfig;
    };
    return InMemoryConfigCache;
}());
export { InMemoryConfigCache };
var ExternalConfigCache = /** @class */ (function () {
    function ExternalConfigCache(cache, logger) {
        this.cache = cache;
        this.logger = logger;
        this.cachedConfig = ProjectConfig.empty;
    }
    ExternalConfigCache.prototype.set = function (key, config) {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!config.isEmpty) {
                            this.cachedSerializedConfig = ProjectConfig.serialize(config);
                            this.cachedConfig = config;
                        }
                        else {
                            // We may have empty entries with timestamp > 0 (see the flooding prevention logic in ConfigServiceBase.fetchLogicAsync).
                            // In such cases we want to preserve the timestamp locally but don't want to store those entries into the external cache.
                            this.cachedSerializedConfig = void 0;
                            this.cachedConfig = config;
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.cache.set(key, this.cachedSerializedConfig)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.logger.configServiceCacheWriteError(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ExternalConfigCache.prototype.get = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var externalSerializedConfig, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.cache.get(key)];
                    case 1:
                        externalSerializedConfig = _a.sent();
                        if (externalSerializedConfig === null || externalSerializedConfig === void 0 || externalSerializedConfig === this.cachedSerializedConfig) {
                            return [2 /*return*/, this.cachedConfig];
                        }
                        this.cachedConfig = ProjectConfig.deserialize(externalSerializedConfig);
                        this.cachedSerializedConfig = externalSerializedConfig;
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.logger.configServiceCacheReadError(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, this.cachedConfig];
                }
            });
        });
    };
    ExternalConfigCache.prototype.getInMemory = function () {
        return this.cachedConfig;
    };
    return ExternalConfigCache;
}());
export { ExternalConfigCache };
