import { useContext, useEffect, useState } from "react";
import ConfigCatContext from "./ConfigCatContext";
function useFeatureFlag(key, defaultValue, user) {
    var configCatContext = useContext(ConfigCatContext);
    if (configCatContext === void 0)
        throw Error("useFeatureFlag hook must be used in ConfigCatProvider!");
    var _a = useState(defaultValue), featureFlagValue = _a[0], setFeatureFlag = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        configCatContext.client.getValueAsync(key, defaultValue, user)
            .then(function (v) { setFeatureFlag(v); setLoading(false); });
    }, [configCatContext, key, defaultValue, JSON.stringify(user)]);
    return { value: featureFlagValue, loading: loading };
}
function useConfigCatClient() {
    var configCatContext = useContext(ConfigCatContext);
    if (configCatContext === void 0)
        throw Error("useConfigCatClient hook must be used in ConfigCatProvider!");
    return configCatContext.client;
}
export { useFeatureFlag, useConfigCatClient };
