var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ExternalConfigCache, PollingMode } from "configcat-common";
import * as configcatcommon from "configcat-common";
import React, { Component } from "react";
import { LocalStorageCache } from "./Cache";
import ConfigCatContext from "./ConfigCatContext";
import { HttpConfigFetcher } from "./ConfigFetcher";
import CONFIGCAT_SDK_VERSION from "./Version";
var initializedClients = new Map();
var ConfigCatProvider = /** @class */ (function (_super) {
    __extends(ConfigCatProvider, _super);
    function ConfigCatProvider(props) {
        var _this = _super.call(this, props) || this;
        var client = _this.initializeConfigCatClient();
        _this.state = { client: client };
        return _this;
    }
    ConfigCatProvider.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c, _d;
        (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.on("clientReady", function () { return _this.clientReady(); });
        (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.client) === null || _d === void 0 ? void 0 : _d.on("configChanged", function (newConfig) { return _this.reactConfigChanged(newConfig); });
    };
    ConfigCatProvider.prototype.componentWillUnmount = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.removeListener("clientReady", function () { return _this.clientReady(); });
        (_d = (_c = this.state) === null || _c === void 0 ? void 0 : _c.client) === null || _d === void 0 ? void 0 : _d.removeListener("configChanged", function (newConfig) { return _this.reactConfigChanged(newConfig); });
        initializedClients.set(this.props.sdkKey, ((_e = initializedClients.get(this.props.sdkKey)) !== null && _e !== void 0 ? _e : 1) - 1);
        if (initializedClients.get(this.props.sdkKey) === 0) {
            (_g = (_f = this.state) === null || _f === void 0 ? void 0 : _f.client) === null || _g === void 0 ? void 0 : _g.dispose();
            initializedClients.delete(this.props.sdkKey);
        }
    };
    ConfigCatProvider.prototype.initializeConfigCatClient = function () {
        var _a;
        var _b = this.props, pollingMode = _b.pollingMode, options = _b.options;
        var sdkKey = this.props.sdkKey;
        var configCatKernel = {
            configFetcher: new HttpConfigFetcher(),
            sdkType: "ConfigCat-React",
            sdkVersion: CONFIGCAT_SDK_VERSION,
            defaultCacheFactory: function (options) { return new ExternalConfigCache(new LocalStorageCache(), options.logger); }
        };
        initializedClients.set(sdkKey, ((_a = initializedClients.get(sdkKey)) !== null && _a !== void 0 ? _a : 0) + 1);
        return configcatcommon.getClient(sdkKey, pollingMode !== null && pollingMode !== void 0 ? pollingMode : PollingMode.AutoPoll, options, configCatKernel);
    };
    ConfigCatProvider.prototype.reactConfigChanged = function (_newConfig) {
        this.setState({ lastUpdated: new Date() });
    };
    ConfigCatProvider.prototype.clientReady = function () {
        this.setState({ lastUpdated: new Date() });
    };
    ConfigCatProvider.prototype.render = function () {
        return (React.createElement(ConfigCatContext.Provider, { value: this.state }, this.props.children));
    };
    return ConfigCatProvider;
}(Component));
export default ConfigCatProvider;
