export function delay(delayMs, obtainCancel) {
    var timerId;
    var promise = new Promise(function (resolve) { return timerId = setTimeout(resolve, delayMs); });
    obtainCancel === null || obtainCancel === void 0 ? void 0 : obtainCancel(function () { return clearTimeout(timerId); });
    return promise;
}
export function errorToString(err, includeStackTrace) {
    if (includeStackTrace === void 0) { includeStackTrace = false; }
    return err instanceof Error
        ? includeStackTrace && err.stack ? err.stack : err.toString()
        : err + "";
}
